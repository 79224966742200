@import '../css/shared';

.slider-a {
  .swiper-button-prev:after,
  .swiper-button-next:after {
    display: none;
  }

  margin: 0 auto;
  .title {
    mark,
    strong,
    b {
      background: none;
      font-family: 'PurinaScript';
      color: inherit;
      padding: 0;
    }
  }
  .message {
    pointer-events: none;
    .btn,
    a {
      pointer-events: auto;
    }
  }
  .slider-navigation {
    .swiper-button-disabled {
      opacity: 0;
      transition: opacity 0s;
    }
  }
  .slider-navigation,
  .slider-pagination {
    z-index: 100;
    pointer-events: none;

    & > div {
      position: relative;
    }

    .swiper-pagination-bullet,
    .slider-button {
      pointer-events: initial;
    }
  }

  .slider-pagination {
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      margin: 0 0.4rem;
      background: $black;
      opacity: 0.2;
      &.swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }

  .slider-button {
    background: transparent !important;
    border: 0;

    svg {
      fill: $white;
    }
  }

  @include media-breakpoint-up(lg) {
    .slider-pagination {
      margin-top: 20px;
    }
    .message {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 100px;
    }

    .slider-button {
      width: 60px !important;
      height: 60px !important;

      svg {
        font-size: 60px !important;
      }

      &.swiper-button-next {
        right: 25px;
      }
      &.swiper-button-prev {
        left: 25px;
      }
    }
  }

  @include media-breakpoint-only(lg) {
    .title {
      font-size: map-get($display-font-sizes, 4);
    }
  }

  @include media-breakpoint-down(xl) {
    .message {
      padding: 0;
    }
  }

  @include media-breakpoint-down(lg) {
    .message {
      text-align: center !important;
    }
  }
}
@include media-breakpoint-up(lg) {
  .slider-a {
    padding-bottom: 25px;
  }
  .disclaimer {
    position: absolute;
    bottom: 0;
  }
}

@include media-breakpoint-up(xl) {
  .slider-a {
    padding-bottom: 0;
  }
}
