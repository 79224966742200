@import '../css/shared';

.banner-slider {
//  max-width: 1920px;
  margin: 0 auto;
  .title { 
    
    mark, strong, b {
      background: none;
      font-family: 'PurinaScript';
      color: inherit;
      padding: 0;
    }

  }
  .message {
    pointer-events: none;
    .btn, a {
      pointer-events: auto;
    }
  }
  .slider-navigation,
  .slider-pagination {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    pointer-events: none;

    & > div {position: relative;}

    .swiper-pagination-bullet,
    .Slider-button {
      pointer-events: initial;
    }
  }

  .slider-pagination .pagination {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: -30px;
    left: 0;
    padding: 0 10px 10px;

    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      margin: 0 0.4rem;
      background: $black;
      opacity: .2;
      &.swiper-pagination-bullet-active {
        opacity: 1;
//        background: $purina-red;
      }
    }
  }

  .Slider-button {
    background: transparent !important;
    border: 0;
    
    svg {fill: $white;}

  }
  @include media-breakpoint-up(lg) {
    .slider-pagination .pagination {
      bottom: -20px;
    }
    .message {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 100px;
    }

    .Slider-button {
      width: 60px !important;
      height: 60px !important;

      svg {
        font-size: 60px !important;
      }
  
      &.swiper-button-next {right: 25px;}
      &.swiper-button-prev {left: 25px;}
  
    }

  }

    @include media-breakpoint-only(lg) {

    .title {font-size: map-get($display-font-sizes, 4);}

  }

  @include media-breakpoint-down(xl) {

    .slider-navigation {
      display: none;
    }

    .message {
      padding: 0;
    }

  }

  @include media-breakpoint-down(lg) {

    .message {
      text-align: center !important;
      
    }

  }
}
@include media-breakpoint-up(lg) {
  .banner-slider {
    padding-bottom: 25px;
  }
  .disclaimer {
    position: absolute;
    bottom: 0;
  }
}

@include media-breakpoint-up(xl) {
  
  .banner-slider {
    padding-bottom: 0;
  }
}